/*eslint-disable */

import Multiselect from 'vue-multiselect'
import ScheduleCalendar from './ScheduleCalendar.vue'
import axios from 'axios';
import blurbForAppointment from '../blurb-for-appointment/index.vue';
import { bus } from '@/main';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import moment from 'moment';
import root from '@/_helpers/roots';
import toast from '@/services/toast';
import updateProfileInfo from '@/components/private/update-profile-info-popup/index.vue';
import uploadMedicalRecords from '@/components/private/health-record-main-popup';
import videoRecording from '@/components/private/video-recording-data/index.vue';

// You need a specific loader for CSS files
let appointmentApiUrl = root.appointmentApiUrl.link;
export default {
  name: 'create-appointmet-form',
  components: {
    'video-record': videoRecording,
    'update-profile-popup': updateProfileInfo,
    Multiselect,
    'blurb-for-appointment': blurbForAppointment,
    'upload-medical-record': uploadMedicalRecords,
    ScheduleCalendar
  },
  props: [],
  data() {
    return {
      full_name: '',
      dob: '',
      height: '',
      weight: '',
      blood_type: '',
      disease: '',
      symptoms: '',
      sendSymptomsArr: [],
      record: '',
      submitted: false,
      selfUserDetailData: {},
      datetime: '',
      subcategory: '',
      selfUserInfo: {},
      symptomsArr: [
        { name: 'Fever', code: 'Fever' },
        { name: 'Acne', code: 'Acne' },
        { name: 'Blocked Nose', code: 'Blocked Nose' },
        { name: 'Runny Nose', code: 'Runny Nose' },
        { name: 'Itching', code: 'Itching' },
        { name: 'Headache', code: 'Headache' },
        { name: 'Vomiting', code: 'Vomiting' },
        { name: 'Constipation', code: 'Constipation' },
        { name: 'Throat pain', code: 'Throat pain' },
        { name: 'Rashes', code: 'Rashes' },
        { name: 'Sneezing', code: 'Sneezing' },
        { name: 'Hairfall', code: 'Hairfall' },
        { name: 'Abdominal Pain', code: 'Abdominal Pain' },
        { name: 'Spots On Skin', code: 'Spots On Skin' },
        { name: 'Obesity', code: 'Obesity' },
        { name: 'Gas', code: 'Gas' },
        { name: 'Erectile Dysfunction', code: 'Erectile Dysfunction' },
        { name: 'Bone Pain', code: 'Bone Pain' },
        { name: 'Dry Skin', code: 'Dry Skin' },
      ],
      uploadedHRList: [],
      minDatetime: new Date(),
      comment: '',
      videRecordState: false,
      videRecordData: '',
      uploadedVideoData: '',
      updatedProfilePopup: false,
      appoinmentWithId: '',
      gender: '',
      myFavDoctor: false,
      draftSymptoms: '',
      draftDisease: '',
      reBookingAppointment: false,
      available_of_day: [],
      available_of_start_time: '',
      available_of_end_time: '',
      available_schedule: null,
      uploadedVideoLink: null,
      appointmentId: '',
      blurbOfAppointmentState: false,
      uploadMedicalRecordsModal: false,
      hRData: null,
      doctorInfo: null,
      availableDates: [],
      availableTimes: [],
      dateToTimeSlots: {},
      selectedDate: null,
      selectedTime: null,
    }
  },
  computed: {
    ...mapGetters([
      'getDoctorDetailByIdGetters',
      'healthRecordsGetters',
    ]),
    doctorInfoByIdFun() {
      this.doctorInfo = this.$store.getters.getDoctorDetailByIdGetters.data;
      return;
    },
    setInitialStateOfInfoFun() {
      if (this.doctorInfo) {
        this.appointmentInfo = this.doctorInfo.latest_appointment_details
        this.myFavDoctor = this.doctorInfo.is_favourite
        this.available_schedule = this.doctorInfo.schedule
        if (this.appointmentInfo) {
          this.symptoms = this.appointmentInfo.symptoms
          this.disease = this.appointmentInfo.disease
          this.draftSymptoms = this.appointmentInfo.symptoms
          this.draftDisease = this.appointmentInfo.disease
          this.reBookingAppointment = true
        }
      }
      return;
    },
  },
  mounted() {
    this.appoinmentWithId = this.$route.params.doctorID
    this.doctorInfoByIdFun
    this.setInitialStateOfInfoFun
    this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
      this.selfUserInfo = res.data.data
      this.dob = this.selfUserInfo.dob
      this.height = this.selfUserInfo.profile.height
      this.weight = this.selfUserInfo.profile.weight
      this.blood_type = this.selfUserInfo.profile.blood_type
      this.gender = this.selfUserInfo.gender
    })
    this.fetchHealthRecords();
    bus.$on('videRecordDataBus', (data) => {
      this.videRecordData = data;
      this.renderUploadVideo(data);
    });
    bus.$on('videRecordStateBus', (data) => {
      this.videRecordState = data;
    });
    bus.$on('blurbOfAppointmentStateBus', (data) => {
      this.blurbOfAppointmentState = data;
    });
    bus.$on('uploadMedicalRecordsModalBus', (data) => {
      this.uploadMedicalRecordsModal = data;
      this.fetchHealthRecords();
    });
  },
  methods: {
    fetchHealthRecords() {
      this.$store.dispatch('getHelthRecordsListApiFun').then(res => {
        this.uploadedHRList = res.data.data
      })
    },
    dayNameToNumber(dayName) {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return daysOfWeek.indexOf(dayName) + 1;
    },

    disabledDates(schedule) {
      const allDays = new Set([1, 2, 3, 4, 5, 6, 7]); // All days of the week
      const enabledDays = new Set();

      for (const week in schedule) {
        const weekData = schedule[week];
        weekData.forEach(dayObj => {
          const dayNumber = this.dayNameToNumber(dayObj.day);
          enabledDays.add(dayNumber);
        });
      }

      // Get the disabled days by subtracting enabled days from all days
      const disabledDays = [...allDays].filter(day => !enabledDays.has(day));

      return { weekdays: disabledDays };
    },

    parseSchedule(data) {
      const currentDate = new Date();
      const availableDates = [];
      const dateToTimeSlots = {};

      Object.entries(data).forEach(([week, slots]) => {
        slots.forEach(slot => {
          const date = this.getDateForDayAndWeek(slot.day, week);
          const dateString = date.toISOString().split('T')[0];

          if (!availableDates.includes(dateString)) {
            availableDates.push(dateString);
          }

          if (!dateToTimeSlots[dateString]) {
            dateToTimeSlots[dateString] = [];
          }

          dateToTimeSlots[dateString].push({
            start: this.createTimeDate(date, slot.start_time),
            end: this.createTimeDate(date, slot.end_time)
          });
        });
      });

      this.availableDates = availableDates;
      this.dateToTimeSlots = dateToTimeSlots;
    },
    getDateForDayAndWeek(day, week) {
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const targetDay = daysOfWeek.indexOf(day);
      const daysToAdd = (targetDay + 7 - currentDay) % 7;
      const weekNumber = parseInt(week.split('_')[1]);

      const resultDate = new Date(currentDate);
      resultDate.setDate(currentDate.getDate() + daysToAdd + (weekNumber - 1) * 7);
      return resultDate;
    },
    createTimeDate(date, timeString) {
      const [hours, minutes] = timeString.split(':').map(Number);
      const newDate = new Date(date);
      newDate.setHours(hours, minutes, 0, 0);
      return newDate;
    },
    updateAvailableTimes() {
      if (this.datetime) {
        const dateString = this.datetime.toISOString().split('T')[0];
        this.availableTimes = this.dateToTimeSlots[dateString] || [];
      } else {
        this.availableTimes = [];
      }

      // Reset selected time when date changes
      this.datetime = null;
    },

    healthRecordModal() {
      document.body.classList.add('popup_open_body')
      this.uploadMedicalRecordsModal = true
    },
    blurbTextPopupOpened() {
      this.blurbOfAppointmentState = true
      document.body.classList.add('popup_open_body')
    },
    renderUploadVideo(videRecordData) {
      var __self = this
      var blob = new Blob(videRecordData, { type: 'video/webm' })
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var videoBase64 = reader.result;
        __self.uploadedVideoLink = videoBase64;
      }
    },
    recordDataGetMethod() {
      if (this.record) {
        let data = this.uploadedHRList.find(item => {
          if (item.id == this.record) {
            this.full_name = item.first_name + ' ' + item.last_name
            this.dob = item.dob
            this.height = item.height
            this.weight = item.weight
            this.blood_type = item.blood_type
            this.gender = item.gender
          }
        });
        return data
      } else {
        this.full_name = this.selfUserInfo.first_name + ' ' + this.selfUserInfo.last_name
        this.dob = this.selfUserInfo.profile.dob
        this.height = this.selfUserInfo.profile.height
        this.weight = this.selfUserInfo.profile.weight
        this.blood_type = this.selfUserInfo.profile.blood_type
        this.gender = this.selfUserInfo.gender
        if (this.draftSymptoms) {
          this.symptoms = this.draftSymptoms
        }
        if (this.draftDisease) {
          this.disease = this.draftDisease
        }
      }
    },
    bookAppointmentFormBeforeSubmit() {
      if (this.videRecordData) {
        var __self = this
        var blob = new Blob(this.videRecordData, { type: 'video/webm' })
        __self.uploadedVideoLink = blob;
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          var videoBase64 = reader.result;
          __self.uploadedVideoData = videoBase64.replace(/^data:video\/(webm);base64,/, "")
        }
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitted = true;
          for (var i = 0; i < this.symptoms.length; i++) {
            this.sendSymptomsArr.push(this.symptoms[i].name)
          }
          if (this.videRecordData) {
            setTimeout(() => {
              let data = {
                full_name: this.full_name,
                dob: this.dob,
                height: this.height,
                weight: this.weight,
                blood_type: this.blood_type,
                gender: this.gender,
                disease: this.disease,
                symptoms: this.symptoms,
                medical_record: this.record,
                doctor_id: this.appoinmentWithId,
                appointment_time: this.datetime,
                video_file: this.uploadedVideoData
                // is_favourite: this.myFavDoctor
              }
              if (this.comment) {
                data.message = this.comment;
              }
              axios.post(appointmentApiUrl, data).then((res) => {
                let notificationPayload = {
                  type: 'appointment_created',
                  data_id: res.data.data.id,
                  message: "Appointment has been created"
                }
                this.$store.dispatch('postNotificationFunc', notificationPayload)
                this.appointmentId = res.data.data.id
                this.blurbTextPopupOpened();
                this.submitted = false
              }).catch((err) => {
                toast.error(err.data.msg || "Request Failed");
                this.submitted = false
              })
            }, 100)
          } else {
            let data = {
              full_name: this.full_name,
              dob: this.dob,
              height: this.height,
              weight: this.weight,
              gender: this.gender,
              blood_type: this.blood_type,
              disease: this.disease,
              symptoms: this.symptoms,
              medical_record: this.record,
              doctor_id: this.appoinmentWithId,
              appointment_time: this.datetime
              // is_favourite: this.myFavDoctor
            }
            if (this.comment) {
              data.message = this.comment;
            }
            axios.post(appointmentApiUrl, data).then((res) => {
              let notificationPayload = {
                type: 'appointment_created',
                data_id: res.data.data.id,
                message: "Appointment has been created"
              }
              this.$store.dispatch('postNotificationFunc', notificationPayload)
              this.appointmentId = res.data.data.id
              this.blurbTextPopupOpened();
              // this.$router.push({ name: 'paymentCheckout',  params: { appointmentId: res.data.data.id } })
              this.submitted = false
            }).catch((err) => {
              toast.error(err.data.msg || "Request Failed");
              this.submitted = false
            })
          }
          return;
        }
      })
    },
    closeCreateAppointmentModal() {
      bus.$emit('bookAppointmentModalBus', false)
      document.body.classList.remove('popup_open_body')
    },
    videoRecordingPopupOpen() {
      this.uploadedVideoLink = null
      window.scrollTo(0, 0)
      document.body.classList.add('popup_open_body')
      this.videRecordState = true
    }
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('popup_open_body')
    next();
  },
  watch: {
    doctorInfoByIdFun() {
      return this.$store.getters.getDoctorDetailByIdGetters;
    },
    setInitialStateOfInfoFun() {
      if (this.doctorInfo) {
        this.appointmentInfo = this.doctorInfo.latest_appointment_details
        this.myFavDoctor = this.doctorInfo.is_favourite
        this.available_schedule = this.doctorInfo.schedule
        if (this.appointmentInfo) {
          this.symptoms = this.appointmentInfo.symptoms
          this.disease = this.appointmentInfo.disease
          this.draftSymptoms = this.appointmentInfo.symptoms
          this.draftDisease = this.appointmentInfo.disease
          this.reBookingAppointment = true
        }
      }
      return;
    }
  },
}